import React from 'react'
import { Box, Stack, Typography } from '@pankod/refine-mui'
import { useDelete, useGetIdentity, useShow } from '@pankod/refine-core'
import { useParams, useNavigate } from '@pankod/refine-react-router-v6'
import { ChatBubble, Delete, Edit, Phone, Place, Star } from '@mui/icons-material'
import { CustomButton } from 'components'
import { propertyInfo } from '../constants'

interface Props {}

const PropertyDetails = ({}:Props) => {
  const { id } = useParams<{ id: string }>();
  const propertyId = id ? parseInt(id, 10) : undefined;
  const property = propertyInfo.find(item => item.id === propertyId);

  if(!property) return <div>Property not found</div>

  const { photo, location, propertyType, title, price, description  } = property;

  return (
    <Box borderRadius="15px" padding="20px" bgcolor="#fcfcfc" width="fit-content">
      <Typography fontSize={25} fontWeight={700} color="#11142d"> Details</Typography>
      <Box mt="20px" display="flex" flexDirection={{ xs: 'column', lg: 'row' }} gap={4}>
        <Box flex={1} maxWidth={764}>
          <img src={photo} alt={title} height={546} style={{ objectFit: "cover", borderRadius: '10px' }} className="property_details-img" />
          <Box mt="15px">
            <Stack direction="row" justifyContent="space-between" flexWrap="wrap" alignItems="center">
              <Typography fontSize={18} fontWeight={500} color="#1142d" textTransform="capitalize">{propertyType}</Typography>
              <Box>{[1, 2, 3, 4, 5].map((star) => <Star key={`star-${star}`} sx={{ color: "#f2c94c" }} />)}</Box>
            </Stack>

            <Stack>
              <Stack direction="row" justifyContent="space-between" flexWrap="wrap" alignItems="center">
                <Box>

                  <Typography fontSize={22} fontWeight={600} color="#1142d" textTransform="capitalize">{title}</Typography>

                  <Stack mt={0.5} direction="row" alignItems="center" gap={0.5}>
                    <Place sx={{ color: "#808191" }} />
                    <Typography fontSize={14} color="#808191">{location}</Typography>
                  </Stack>
                </Box>

                <Box>
                  <Typography fontSize={18} fontWeight={600} color="#475be8">${price}</Typography>
                  <Stack direction="row" alignItems="flex-end" gap={1}>
                    <Typography fontSize={14} mb={0.5} color="#808191">per month</Typography>
                  </Stack>
                </Box>
              </Stack>
              <Stack mt="25px" direction="column" gap="10px"> Description:
                <Typography fontSize={18} color="#808191">{description}</Typography>
              </Stack>
            </Stack>
          </Box>
        </Box>
        <Box width="100%" flex={1} maxWidth={326} display="flex" flexDirection="column" gap="20px">
          <Stack width="100%" p={2} direction="column" alignItems="center" border="1px solid #E4E4E4" borderRadius={2} gap="10px">
            <Stack mt={2} justifyContent="center" alignItems="center" textAlign="center">
              <img src="https://img.lovepik.com/free-png/20211213/lovepik-penguin-animal-small-avatar-illustration-design-png-image_401570104_wh1200.png" alt="avatar" width={90} height={90} style={{ borderRadius: '100%', objectFit: 'cover'}} />
              <Box mt="15px">
                <Typography fontSize={18} fontWeight={600} color="#11142D">{property.creator}</Typography>
                <Typography fontSize={14} color="#808191">{property.creatormail}</Typography>
                <Typography fontSize={14} color="#808191">Agent</Typography>
              </Box>
              <Stack mt="15px" direction="row" alignItems="center" gap={1}>
                <Place sx={{color: "#808191"}} />
                <Typography fontSize={14} color="#808191">Iasi, Romania</Typography>
              </Stack>
                
              <Stack width="100%" mt="25px" direction="row" flexWrap="wrap" gap={2}>
                <CustomButton 
                  title={'Message'} 
                  icon={<ChatBubble /> }
                  backgroundColor="#475BE8"
                  color="#fcfcfc"
                  fullWidth
                  handleClick={() => {}}
                />
                <CustomButton 
                  title={'Call'}
                  backgroundColor={'#2ed480'}
                  color="#fcfcfc"
                  fullWidth
                  icon={ <Phone />}
                  handleClick={() => {}}
                />
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Box>
  )
}

export default PropertyDetails
