import React from 'react'
import { useList } from '@pankod/refine-core'
import { Box, Typography } from '@pankod/refine-mui'
import { AgentCard } from 'components'
import { agentProfile } from '../constants'

const Agents = () => {

 





  return (
    <Box>
      <Typography fontSize={25} fontWeight={700} color="#11142d">
        Agents List

      </Typography>
      <Box mt="20px" sx={{ display: 'flex', flexWrap: "wrap", gap: '20px', bgcolor: '#fcfcfc'}}>
        {agentProfile.map((agent) => (
          <AgentCard key={agent._id} id={agent._id} name={agent.name} email={agent.email} avatar={agent.avatar}/>
        ))}

      </Box>
      
    </Box>
  )
}

export default Agents
