export const propertyReferralsInfo = [
 {
   title: 'Social Media',
   percentage: 64,
   color: '#6C5DD3',
 },
 {
   title: 'Marketplace',
   percentage: 40,
   color: '#7FBA7A',
 },
 {
   title: 'Websites',
   percentage: 50,
   color: '#FFCE73',
 },
 {
   title: 'Digital Ads',
   percentage: 80,
   color: '#FFA2C0',
 },
 {
   title: 'Others',
   percentage: 15,
   color: '#F45252',
 },
];

type PropertyInfo = {
  id: number;
  _id: string;
  title: string;
  description: string;
  propertyType: string;
  location: string;
  price: string;
  photo: string;
  creator: string;
  creatormail: string;
}

export const propertyInfo: PropertyInfo[] = [
  {
    id: 1,
    _id: '1',
    title: 'Luxury Villa',
    description: 'Just a luxurious awesome vacation Villa in the middle of the City.',
    propertyType: 'villa',
    location: 'Bucharest, Romania',
    price: "4000",
    photo: 'http://res.cloudinary.com/dz1gz3bhk/image/upload/v1677348960/dqrxx6oxe58i5uvmlji5.jpg',
    creator: 'A Cajvan',
    creatormail: 'andrei@cajvan.com',
  },
  {
    id: 2,
    _id: '2',
    title: 'Ultra Central Apartments',
    description: 'The best Apartments in town, just 5 minutes away from the city center.',
    propertyType: 'apartment',
    location: 'Bucharest, Romania',
    price: "2000",
    photo: 'http://res.cloudinary.com/dz1gz3bhk/image/upload/v1677366910/vm5jtreayxlfujxlghim.png',
    creator: 'A Cajvan',
    creatormail: 'andrei@cajvan.com'
  },
  {
    id: 3,
    _id: '3',
    title: 'Copou Residence',
    description: 'The best studios in Copou.',
    propertyType: 'studio',
    location: 'Iasi, Romania',
    price: "550",
    photo: 'http://res.cloudinary.com/dz1gz3bhk/image/upload/v1677366939/tuzsuedbzyaxebszmdej.png',
    creator: 'A Cajvan',
    creatormail: 'andrei@cajvan.com'
  },
  {
    id: 4,
    _id: '4',
    title: 'Pension Daisy',
    description: 'Flowers everywhere.',
    propertyType: 'villa',
    location: 'Cluj Napoca, Romania',
    price: "300",
    photo: 'http://res.cloudinary.com/dz1gz3bhk/image/upload/v1677348782/dn86jo9a1uth8qsogk6t.jpg',
    creator: 'A Cajvan',
    creatormail: 'andrei@cajvan.com'
  },
  {
    id: 5,
    _id: '5',
    title: 'Pension Sofie',
    description: 'A village from Brasov in miniature that perfectly combines the traditional with the modern.',
    propertyType: 'townhouse',
    location: 'Brasov, Romania',
    price: "3500",
    photo: 'http://res.cloudinary.com/dz1gz3bhk/image/upload/v1677350384/r6mh957s6sakyxloqsle.png',
    creator: 'A Cajvan',
    creatormail: 'andrei@cajvan.com'
  },
  {
    id: 6,
    _id: '6',
    title: 'Hotel Pleiada',
    description: 'The only 5 Star hotel in Bucovina.',
    propertyType: 'townhouse',
    location: 'Iasi, Romania',
    price: "1200",
    photo: 'http://res.cloudinary.com/dz1gz3bhk/image/upload/v1677366567/jd1cwxaotnqmufhby2yp.jpg',
    creator: 'A Cajvan',
    creatormail: 'andrei@cajvan.com'
  },
  {
    id: 7,
    _id: '7',
    title: 'Hotel Motto by Hilton',
    description: "Towering 42 stories over Chelsea, our hotel is two blocks from Madison Square Park and the Flatiron Building. We're about a mile from The High Line, Chelsea Market, Times Square, and Greenwich Village. Dozens of unique eateries, bars, museums, and shops are also a short walk from our doors. Or unwind with a drink and bite to eat at our bar and restaurant.",
    propertyType: 'townhouse',
    location: 'New York, USA',
    price: "1450",
    photo: 'http://res.cloudinary.com/dz1gz3bhk/image/upload/v1677366751/ctizl8cahymxkqnl7jwm.png',
    creator: 'A Cajvan',
    creatormail: 'andrei@cajvan.com'
  },
  {
    id: 8,
    _id: '8',
    title: 'Barter Luxury Penthouse',
    description: 'The best Apartments in town, just 5 minutes away from the city center.',
    propertyType: 'apartment',
    location: 'Bucharest, Romania',
    price: "2000",
    photo: 'http://res.cloudinary.com/dz1gz3bhk/image/upload/v1677367028/gjqmsdku9w80ry1gxfpe.png',
    creator: 'A Cajvan',
    creatormail: 'andrei@cajvan.com'
  }
]


type AgentProfile = {
  id: number;
  _id: string;
  name: string;
  email: string;
  avatar: string;
}

export const agentProfile: AgentProfile[] = [
  {
    id: 1,
    _id: '1',
    name: 'A Cajvan',
    email: 'andrei@cajvan.com',
    avatar: 'https://img.lovepik.com/free-png/20211213/lovepik-penguin-animal-small-avatar-illustration-design-png-image_401570104_wh1200.png',
  },
  {
    id: 2,
    _id: '2',
    name: 'Andrei Cajvan',
    email: 'cajvan01@gmail.com',
    avatar: 'https://p.kindpng.com/picc/s/78-786207_user-avatar-png-user-avatar-icon-png-transparent.png',
  }
]