import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

type Props = {
  open: boolean;
  handleClose: () => void;
};

const TrafficModal: React.FC<Props> = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{"Server Alert"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Hello, there is a lot of traffic on the backend server and the response time is way too long for this request to complete. Please try again later.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} sx={{color: 'blue'}}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TrafficModal;
