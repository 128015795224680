import React from 'react'
import { useOne } from '@pankod/refine-core'
import { Profile } from 'components'
import { useParams } from '@pankod/refine-react-router-v6'
import { agentProfile } from '../constants'


interface Props {}

const AgentProfile = ({}:Props) => {
  const { id } = useParams<{ id: string }>();
  const agentID = id ? parseInt(id, 10) : undefined;
  const myprofile = agentProfile.find(item => item.id === agentID);

  if(!myprofile) return <div>Agent not found</div>

  const { name, email, avatar } = myprofile;

  return (
    <Profile
      type="Agent"
      name={name}
      email={email}
      avatar={avatar}
      />
  )
}

export default AgentProfile
